// original styles from borgarsogusafn.is

// main.css after gulp build of borgarsogusafn.is project
@import "../../vendor/borgarsogusafn.is/development/dist/styles/main";
// variables needed - they're being used in photo gallery & popup components
@import "../../vendor/borgarsogusafn.is/development/app/styles/variables";

// extra variables for the popup & gallery
$color-popup-background: #f5f4f3;
$color-popup-border: #dfdedd;
$color-popup-header: #364650;
$color-popup-bodytext: #6f6f70;
$color-popup-social: #89949c;
$color-popup-facebook: #3b5998;
$color-popup-twitter: #1da1f2;
$color-popup-blue: #33669a;
$color-popup-orange: #f26722;
$color-gallery-hover: #fff9ef;

// correcting the main background image path
body {
	color: $color-popup-bodytext;
	font-family: $merriweather;
	background: url('../styles/images/assets/pattern_bg_main.png') top center;
}

#content {
	font-size: 13px;
}

.wrapper {
	&--padded {
		box-sizing: border-box;
		padding: 15px;
	}
}

// Borgarsögusafn css er að skemma allt
input[type="radio"] {
	appearance: radio !important;
}

input[type="checkbox"] {
	appearance: checkbox !important;
}

// v1 tweaks
.thumbnails {
	list-style-type: none;

	.thumbnail {
		padding: 10px;
		pointer-events: none;
	}
}

.input-group.btn-block {
	max-width: 200px;
}

.footer.layout a[href*="mailto"] {
	display: inline;
}

// components
@import "components/photo-gallery";
@import "components/photo-popup";
