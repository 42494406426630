
.photo-gallery {
	width: 100%;
	background: $color1;
	color: $color-popup-bodytext;

	.col {
		display: block;
		width: 20%;
		padding: 15px;
		float: left;
		background: transparent;
		border: solid 1px transparent;
		font-family: $merriweather;
		text-align: center;
		text-decoration: none;
		transition: background .3s, border .3s;

		img {
			max-height: 240px;
			margin-bottom: 16px;
			border: solid 5px $color1;
			box-shadow: 0 0 0 1px $color-popup-border;
		}

		span {
			display: block;
		}

		&:hover {
			background: $color-gallery-hover;
			border-color: $color-popup-border;
		}

		&::before, &::after {
			content: '';
			display: table;
			width: 100%;
			clear: both;
		}
	}
}

.photo-gallery__top {
	position: relative;
	overflow: hidden;
	border-bottom: solid 1px $color-popup-border;

	h1 {
		padding: 20px 28px;
		font-family: $futuramedium;
		font-size: 20px;
		line-height: 35px;

		span {
			color: $color-popup-header;
		}
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.photo-gallery__navi {
	margin: 0;
	padding: 0;
	float: right;
	font-size: 0;
	list-style-type: none;

	li {
		position: relative;
		display: inline-block;
		height: 75px;
		background: transparent;
		border-left: solid 1px transparent;
		border-right: solid 1px transparent;
		font-size: 17px;
		transition: background .3s, color .3s, border .3s;

		a {
			padding: 27px;
			color: $color-popup-social;
			text-decoration: none;
			transition: color .3s;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: -1px;
				transform: translate3d(0, -50%, 0);
				width: 1px;
				height: 35px;
				background: $color-popup-border;
				transition: background .3s;
			}
		}

		&:hover {
			background: $color-popup-background;
			border-color: $color-popup-border;

			a {
				color: $color-popup-orange;
			}

			+ li a::before {
				background: transparent;
			}
		}

		&:last-child {
			border-right: 0;
		}
	}

	i {
		vertical-align: middle;
		pointer-events: none;

		&.icon-close2::before {
			display: inline-block;
			transform: scale(.7);
		}

		&::before {
			line-height: 75px;
		}
	}

	.photo-gallery__tags-toggle--open {
		background: $color-popup-background;
		border-color: $color-popup-border;

		a {
			color: $color-popup-orange;
		}

		+ li a::before {
			background: transparent;
		}
	}
}

.photo-gallery__search {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	transform: translate3d(100%, 0, 0);
	width: 300px;
	height: 75px;
	margin: 0;
	padding: 20px;
	overflow: hidden;
	background: $color1;
	transition: transform .3s;

	::placeholder {
		text-transform: none;
	}

	input[type="text"] {
		width: 180px;
		margin: 0 20px;
		padding: 0;
		background: $color1;
		border: 0;
		font-family: $opensans;
		font-size: 14px;
	}

	i {
		vertical-align: middle;
	}

	.icon-close2 {
		display: inline-block;
		transform: scale(.7);
		cursor: pointer;

		&:hover {
			color: $color-popup-orange;
		}

		// extending the clickable area of a close icon
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			width: 220%;
			height: 220%;
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate3d(0, -50%, 0);
		width: 1px;
		height: 35px;
		background: $color-popup-border;
		transition: background .3s;
	}

	&--open {
		transform: translate3d(0, 0, 0);
	}
}

.photo-gallery__tags {
	position: relative;
	background: $color29;
	font-family: $merriweather;
	font-size: 14px;

	> div {
		max-height: 0;
		padding: 0 28px;
		overflow: hidden;
		transition: max-height .3s, padding .3s;
	}

	span {
		color: $color-popup-header;
		font-weight: 700;
	}

	a {
		color: $color1;
		text-decoration: none;
		transition: color .3s;

		&:hover {
			color: $color-popup-header;
		}
	}

	ul {
		display: inline;
		list-style-type: none;

		li {
			display: inline;

			&::after {
				content: ' · ';
			}

			&:last-child::after {
				display: none;
			}
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: -10px;
		right: 100px;
		z-index: 2;
		transform: scaleY(0);
		transform-origin: bottom;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent $color29 transparent;
		transition: transform .3s;
	}

	&--open {

		> div {
			max-height: 400px;
			padding: 28px;
		}

		&::before {
			transform: scaleY(1);
		}
	}
}

.photo-gallery__container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	padding: 30px 15px;

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.photo-gallery__id {
	color: $color-popup-bodytext;
	font-size: 13px;
	line-height: 1;
}

.photo-gallery__author {
	color: $color-popup-blue;
	font-size: 14px;
}

@media (max-width: 1024px) {

	.photo-gallery .col {
		width: 25%;
	}
}

@media (max-width: 800px) {

	.photo-gallery .col {
		width: 33.33333%;
	}
}

@media (max-width: 640px) {

	.photo-gallery {
		font-size: 12px;
		line-height: 20px;

		&__top {
			min-height: 49px;

			h1 {
				padding: 12px 16px;
				font-size: 16px;
				line-height: 25px;
			}
		}

		&__navi {

			li {
				height: 49px;
				font-size: 12px;

				a {
					padding: 17px;

					&::before {
						height: 18px;
					}
				}
			}

			i::before {
				line-height: 49px;
			}
		}

		&__search {
			width: 260px;
			height: 49px;
			padding: 6px 16px;

			input[type="text"] {
				margin: 0 12px;
			}

			&::before {
				height: 18px;
			}
		}

		&__tags {
			font-size: 12px;

			> div {
				padding: 0 16px;
			}

			&::before {
				top: -6px;
				right: 66px;
				border-width: 0 6px 6px 6px;
			}
		}

		&__tags--open > div {
			padding: 16px;
		}
	}
}

@media (max-width: 480px) {

	.photo-gallery .col {
		width: 50%;
	}

	.photo-gallery__top h1 {
		float: left;
	}
}

@media (max-width: 360px) {

	.photo-gallery .col {
		width: 100%;
	}
}
