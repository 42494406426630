
.photo-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 999;
	transform: translate3d(-50%, -50%, 0);
	min-width: 90vw;
	background: $color-popup-background;
	border-radius: 5px;
	font-family: $merriweather;
	font-weight: 400;
	font-size: 14px;

	img {
		max-width: 100%;
		max-height: calc(100vh - 160px);
		border: solid 10px $color1;
		box-shadow: 0 0 0 1px $color-popup-border, 0 2px 8px rgba(0, 0, 0, .2);
	}
}

.photo-popup__top {
	min-height: 75px;
	background: $color1;
	border-bottom: solid 1px $color-popup-border;
	border-radius: 5px 5px 0 0;

	h1 {
		padding: 20px 28px;
		color: $color-popup-header;
		font-family: $futuraheavy;
		font-size: 20px;
		line-height: 35px;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.photo-popup__navi {
	margin: 0;
	padding: 0;
	float: right;
	font-size: 0;
	list-style-type: none;

	li {
		position: relative;
		display: inline-block;
		height: 75px;
		background: transparent;
		border-left: solid 1px transparent;
		border-right: solid 1px transparent;
		font-size: 17px;
		transition: background .3s, color .3s, border .3s;

		a {
			padding: 27px;
			color: $color-popup-social;
			text-decoration: none;
			transition: color .3s;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: -1px;
				transform: translate3d(0, -50%, 0);
				width: 1px;
				height: 35px;
				background: $color-popup-border;
				transition: background .3s;
			}
		}

		&:hover {
			background: $color-popup-background;
			border-color: $color-popup-border;

			a {
				color: $color-popup-orange;
			}

			+ li a::before {
				background: transparent;
			}
		}

		&:last-child {
			border-right: 0;
		}
	}

	i {
		vertical-align: middle;

		// size adjustment so it's as in the design
		&.icon-left::before,
		&.icon-right::before,
		&.icon-close2::before {
			display: inline-block;
			transform: scale(.75);
		}

		&::before {
			line-height: 75px;
		}
	}
}

.photo-popup__social {
	display: block;
	max-width: 0;
	float: right;
	overflow: hidden;
	white-space: nowrap;
	transition: max-width .5s;

	li {
		border: 0;

		a {
			padding: 27px 14px;

			&::before {
				display: none;
			}
		}
	}

	.icon-facebook {
		color: $color-popup-blue;
	}

	.icon-twitter {
		color: $color-popup-twitter;
	}
}

.photo-popup__navi .photo-popup__share--open {
	padding-right: 12px;
	background: $color-popup-background;
	border-left: solid 1px $color-popup-border;
	border-right: solid 1px $color-popup-border;

	.photo-popup__social {
		max-width: 100px
	}

	// removing the vertical divider between social icons
	+ li a::before {
		background: transparent;
	}

	// to avoid showing double line when hovering left arrow while share is opened
	+ li:hover {
		border-left-color: transparent;
	}
}

.photo-popup__middle {
	position: relative;
}

.photo-popup__left {
	width: calc(100% - 320px);
	padding: 28px;
	text-align: center;
}

.photo-popup__right {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 184px;
	width: 320px;
	padding: 28px 28px 28px 0;
	overflow-y: auto;

	h2 {
		margin-bottom: 6px;
		color: $color-popup-header;
		font-size: 14px;
		font-weight: 700;
	}

	p {
		margin-bottom: 18px;
	}
}

.photo-popup__tags {

	span {
		color: $color-popup-header;
	}

	a {
		color: $color-popup-blue;
		text-decoration: none;
		transition: color .3s;

		&:hover {
			color: $color-popup-orange;
		}
	}

	ul {
		display: inline;
		list-style-type: none;

		li {
			display: inline;

			&::after {
				content: ' · ';
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}

.photo-popup__info {
	border-top: solid 1px $color-popup-border;
	border-bottom: solid 1px $color-popup-border;

	span {
		display: block;
		position: relative;
		padding: 18px 0;
		color: $color-popup-social;
		font-family: $futuramedium;
		cursor: pointer;
		transition: color .3s;

		&::after{
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate3d(-24px, -50%, 0);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 5px 0 5px;
			border-color: $color-popup-social transparent transparent transparent;
			transition: transform .3s, border .3s;
		}

		&:hover {
			color: $color-popup-orange;

			&::after {
				border-top-color: $color-popup-orange;
			}
		}
	}

	ul {
		max-height: 0;
		overflow: hidden;
		padding: 0;
		opacity: 0;
		list-style-type: none;
		transition: max-height .5s, padding .5s, opacity .5s;

		strong {
			color: $color-popup-social;
			font-weight: 400;
		}
	}

	&--open {

		span::after {
			transform: translate3d(-24px, -50%, 0) rotate(-180deg);
		}

		ul {
			max-height: 300px;
			padding: 0 0 18px;
			opacity: 1;
		}

	}
}

.photo-popup__bottom {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 320px;
	padding: 0 28px 28px 0;
	font-size: 13px;

	ul {
		list-style-type: none;
	}

	.button {
		display: block;
		width: 100%;
		height: 60px;
		margin-bottom: 18px;
		background: $color1;
		color: $color-popup-social;
		border: solid 1px $color-popup-border;
		border-radius: 5px;
		box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
		font-family: $futuramedium;
		font-size: 16px;
		line-height: 58px;
		text-align: center;
		text-decoration: none;
		transition: color .3s, border .3s;

		i {
			display: block;
			width: 60px;
			float: left;
			border-right: solid 1px $color-popup-border;
			font-size: 20px;
			transition: border .3s;

			&::before {
				line-height: 58px;
			}
		}

		&:hover {
			color: $color-popup-orange;
			border-color: $color-popup-orange;

			i {
				border-color: $color-popup-orange;				
			}
		}
	}

	li:last-child {

		a {
			display: block;
			position: relative;
			padding-left: 36px;
			color: $color-popup-blue;
			transition: color .3s;
			text-decoration: none;

			&:hover {
				color: $color-popup-orange;

				i {
					color: $color-popup-orange;
				}
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate3d(0, -50%, 0);
			color: $color-popup-social;
			font-size: 18px;
			transition: color .3s;
		}
	}
}

@media (min-width: 1600px) {

	.photo-popup {
		min-width: 75vw;
	}
}

@media (max-width: 960px) {

	.photo-popup {
		width: 100%;
		max-height: 100%;
		overflow: auto;

		&__left,
		&__right,
		&__bottom {
			position: static;
			width: auto;
		}

		&__right {
			padding: 0 28px;
		}

		&__bottom {
			padding: 28px;
		}
	}
}

@media (max-width: 640px) {

	.photo-popup {
		font-size: 12px;
		line-height: 20px;

		&__top {
			min-height: 49px;

			h1 {
				padding: 12px 16px;
				font-size: 16px;
				line-height: 25px;
			}
		}

		&__navi {

			li {
				height: 49px;
				font-size: 12px;

				a {
					padding: 17px;

					&::before {
						height: 18px;
					}
				}
			}

			i::before {
				line-height: 49px;
			}
		}

		&__left {
			padding: 16px;
		}

		&__right {
			padding: 0 16px;
		}

		&__info {

			span {
				padding: 10px 0;
			}
		}

		&__bottom {
			padding: 16px;
			font-size: 11px;

			.button {
				height: 40px;
				font-size: 14px;
				line-height: 38px;

				i {
					width: 40px;
					font-size: 16px;

					&::before {
						line-height: 38px;
					}
				}
			}

			li:last-child {

				a {
					padding-left: 24px;
				}

				i {
					font-size: 14px;
				}
			}
		}
	}
}
